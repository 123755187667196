<template>
  <div>
    <el-button type="primary" @click="showSyncDialogAction" class="sync-button"
      >Data Synchronization</el-button
    >
    <!-- 搜索框 -->
    <el-input
      v-model="searchQuery"
      placeholder="Please enter a synchronization name or SKU"
      class="search-box"
      @keyup.enter="searchDataSync"
      style="width: 300px; float: right; margin-bottom: 20px"
    >
      <template #append>
        <el-button @click="searchDataSync">search</el-button>
      </template>
    </el-input>
    <el-table :data="syncItems" style="width: 100%" v-loading="pageloading">
      <el-table-column prop="name" label="Sync Name"></el-table-column>
      <el-table-column
        prop="skuShopify"
        label="SKU (Shopify)"
      ></el-table-column>
      <el-table-column prop="skuEbayOne" label="SKU (Ebay-A)"></el-table-column>
      <el-table-column prop="skuEbayTwo" label="SKU (Ebay-B)"></el-table-column>
      <el-table-column
        prop="skuWordpress"
        label="SKU (Wordpress)"
      ></el-table-column>
      <el-table-column
        prop="virtualStock"
        label="Virtual Stock"
      ></el-table-column>
      <el-table-column prop="realStock" label="Actual Stock"></el-table-column>
      <el-table-column prop="updatedAt" label="Last Updated"></el-table-column>
      <el-table-column label="Actions" align="center">
        <template #default="{ row }">
          <div class="action-buttons">
            <el-button size="mini" type="primary" style="margin-left: 10px;" @click="editItem(row)">Edit</el-button>
            <el-button
              size="mini"
              type="primary"
              @click="openSyncStockDialog(row)"
              >Sync Stock</el-button
            >
            <el-button size="mini" type="danger" @click="deleteItem(row)"
              >Delete</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[20, 40, 60, 80]"
      :page-size="20"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    >
    </el-pagination>

    <!-- 同步库存对话框 -->
    <el-dialog title="Sync Stock" v-model="showSyncStockDialog" width="400px">
      <el-form :model="syncStockData">
        <el-form-item label="virtualStock" label-width="120px">
          <el-input
            v-model="syncStockData.virtualStock"
            type="number"
            placeholder="Please enter the quantity"
            style="width: 200px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="realStock" label-width="120px">
          <el-input
            v-model="syncStockData.realStock"
            type="number"
            placeholder="Please enter the quantity"
            style="width: 200px;"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showSyncStockDialog = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="syncStock"
            :loading="syncLoading"
            :disabled="syncLoading"
            >Confirm</el-button
          >
        </div>
      </template>
    </el-dialog>

    <el-dialog title="" v-model="showSyncDialog" width="400px">
      <el-form :model="formData">
        <el-form-item label="Sync Name" label-width="120">
          <el-input v-model="formData.name" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="SKU (Shopify)" label-width="120">
          <el-select
            v-model="formData.skuShopify"
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a sku"
            :remote-method="fetchSkuDataShopify"
            :loading="loading"
            style="width: 200px"
          >
            <el-option
              v-for="item in shopifyOptions"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="SKU (Ebay-A)" label-width="120">
          <el-select
            v-model="formData.skuEbayOne"
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a itemId"
            :remote-method="
              (inputValue) => fetchSkuDataEbay(inputValue, 'dahao')
            "
            :loading="loading"
            style="width: 200px"
          >
            <el-option
              v-for="item in ebayOneOptions"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="SKU (Ebay-B)" label-width="120">
          <el-select
            v-model="formData.skuEbayTwo"
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a itemId"
            :remote-method="
              (inputValue) => fetchSkuDataEbay(inputValue, 'xiaohao')
            "
            :loading="loading"
            style="width: 200px"
          >
            <el-option
              v-for="item in ebayTwoOptions"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="SKU (WordPress)" label-width="120">
          <el-select
            v-model="formData.skuWordpress"
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a sku"
            :remote-method="fetchSkuDataWordpress"
            :loading="loading"
            style="width: 200px"
          >
            <el-option
              v-for="item in wordpressOptions"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <div class="dialog-footer">
          <el-button @click="showSyncDialog = false">Cancel</el-button>
          <el-button type="primary" @click="syncData">Save</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import apiClient from "@/apiClient";

export default {
  name: "DataSync",
  data() {
    return {
      syncLoading: false,
      searchQuery: "",
      syncItems: [],
      loading: false,
      pageloading: false,
      currentPage: 1,
      totalItems: 0,
      showSyncDialog: false,
      showSyncStockDialog: false, // 控制同步库存对话框的显示
      shopifyOptions: [],
      ebayOneOptions: [],
      ebayTwoOptions: [],
      wordpressOptions: [],
      formData: {
        name: "",
        skuShopify: "",
        skuEbayOne: "",
        skuEbayTwo: "",
        skuWordpress: "",
        virtualStock: "",
        realStock: "",
      },
      syncStockData: {
        id: null, // 当前行的ID
        name: "", // 同步的名称
        skuShopify: "",
        skuEbayOne: "",
        skuEbayTwo: "",
        skuWordpress: "",
        virtualStock: "",
        realStock: "",
      },
    };
  },
  methods: {
    showSyncDialogAction() {
      this.formData = {};
      this.showSyncDialog = true;
    },
    searchDataSync() {
      // 重新加载数据，并进行搜索
      this.currentPage = 1;
      this.fetchSyncData();
    },
    syncData() {
      // 检查 formData 是否包含 id 属性来决定是创建还是更新
      if (this.formData.id) {
        // 更新现有记录
        this.updateItem(this.formData);
      } else {
        // 创建新记录
        this.createItem(this.formData);
      }
      this.showSyncDialog = false;
    },

    createItem(data) {
      console.log("Storage method");
      if (this.ebayOneOptions[0]) data.idEbayOne = this.ebayOneOptions[0].value;
      if (this.ebayTwoOptions[0]) data.idEbayTwo = this.ebayTwoOptions[0].value;
      if (this.shopifyOptions[0]) data.idShopify = this.shopifyOptions[0].value;
      if (this.wordpressOptions[0])
        data.idWordpress = this.wordpressOptions[0].value;
      apiClient
        .post("/dataSync/save", data)
        .then(() => {
          this.$message({
            type: "success",
            message: "Synchronization task created successfully!",
          });
          this.fetchSyncData(this.currentPage);
          this.showSyncDialog = false;
        })
        .catch((error) => {
          console.error("Failed to create synchronization task", error);
          if (error.response && error.response.data) {
            this.$message.error(`Creation failed: ${error.response.data}`);
          } else {
            this.$message.error(
              "Failed to create synchronization task, please try again later"
            );
          }
          this.showSyncDialog = true;
        });
    },

    updateItem(data) {
      if (this.ebayOneOptions[0]) data.idEbayOne = this.ebayOneOptions[0].value;
      if (this.ebayTwoOptions[0]) data.idEbayTwo = this.ebayTwoOptions[0].value;
      if (this.shopifyOptions[0]) data.idShopify = this.shopifyOptions[0].value;
      if (this.wordpressOptions[0])
        data.idWordpress = this.wordpressOptions[0].value;
      console.log("debugger");
      apiClient
        .put(`/dataSync/update`, data)
        .then(() => {
          this.$message({
            type: "success",
            message: "Synchronization task updated successfully!",
          });
          this.fetchSyncData(this.currentPage);
          this.showSyncDialog = false;
        })
        .catch((error) => {
          console.error("Failed to update synchronization task", error);
          if (error.response && error.response.data) {
            this.$message.error(`Update failed: ${error.response.data}`);
          } else {
            this.$message.error(
              "Failed to update synchronization task, please try again later"
            );
          }
          this.showSyncDialog = true;
        });
    },
    fetchSyncData(page = 1) {
      const params = {
        page: page,
        size: 20,
        search: this.searchQuery,
      };
      this.pageloading = true;
      apiClient
        .get("/dataSync/list", { params })
        .then((response) => {
          this.syncItems = response.data.items;
          this.totalItems = response.data.total;
          this.pageloading = false;
        })
        .catch((error) => {
          console.error("Failed to load data", error);
          this.pageloading = false;
        });
    },
    editItem(item) {
      this.formData = { ...item };
      this.showSyncDialog = true;
    },
    deleteItem(item) {
      this.$confirm(
        `Are you sure you want to delete the synchronization task "${item.name}" ?`,
        "Warning",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          apiClient
            .delete(`/dataSync/delete/${item.id}`)
            .then(() => {
              this.$message({
                type: "success",
                message: "Deleted successfully!",
              });
              this.fetchSyncData(this.currentPage);
            })
            .catch((error) => {
              console.error("Failed to delete", error);
              this.$message({
                type: "error",
                message: `Deletion failed: ${
                  error.response.data || "Unknown error"
                }`,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Deletion canceled",
          });
        });
    },
    handleSizeChange(size) {
      this.fetchSyncData(this.currentPage, size);
    },
    handleCurrentChange(page) {
      this.fetchSyncData(page);
    },

    openSyncStockDialog(row) {
      this.syncStockData = { ...row };
      this.showSyncStockDialog = true;
    },

    async syncStock() {
      if (this.syncLoading) return; // Prevent double-click
      this.syncLoading = true;
      try {
        await apiClient.post("/dataSync/syncStock", this.syncStockData);
        this.$message.success("Stock synchronized successfully!");
        this.showSyncStockDialog = false;
      } catch (error) {
        this.$message.error(
          "Failed to synchronize stock: " +
            (error.response?.data || "Unknown error")
        );
      } finally {
        this.syncLoading = false; // Reset loading state after completion
        this.fetchSyncData(this.currentPage);
      }
    },

    fetchSkuDataShopify(sku) {
      const platform = "shopify";
      if (sku.length > 2) {
        this.loading = true;
        apiClient
          .get(`/dataSync/sku?platform=${platform}&sku=${sku + ""}`)
          .then((response) => {
            const node = response.data;
            this.shopifyOptions[0] = { value: node.id, label: node.sku };
            this.loading = false;
          })
          .catch((error) => console.error("Failed to fetch SKU:", error));
      }
    },
    fetchSkuDataEbay(sku, storeName) {
      const platform = "ebay";
      const _storeName = storeName;
      if (_storeName === "dahao") this.ebayOneOptions = [];
      if (_storeName === "xiaohao") this.ebayTwoOptions = [];
      if (sku.length > 2) {
        this.loading = true;
        apiClient
          .get(
            `/dataSync/sku?platform=${platform}&sku=${
              sku + ""
            }&storeName=${storeName}`
          )
          .then((response) => {
            const node = response.data;
            if (_storeName === "dahao")
              this.ebayOneOptions[0] = { value: node.id, label: node.sku };
            if (_storeName === "xiaohao")
              this.ebayTwoOptions[0] = { value: node.id, label: node.sku };
            this.loading = false;
          })
          .catch((error) => console.error("Failed to fetch SKU:", error));
      }
    },
    fetchSkuDataWordpress(sku) {
      const platform = "wordpress";
      if (sku.length > 2) {
        this.loading = true;
        apiClient
          .get(`/dataSync/sku?platform=${platform}&sku=${sku + ""}`)
          .then((response) => {
            const item = response.data;
            this.wordpressOptions[0] = { value: item.id, label: item.sku };
            this.loading = false;
          })
          .catch((error) => console.error("Failed to fetch SKU:", error));
      }
    },
  },
  created() {
    this.fetchSyncData(this.currentPage);
  },
};
</script>

<style scoped>
.action-buttons {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  gap: 8px; /* 按钮之间的间距 */
  justify-content: center; /* 水平居中对齐 */
}

.el-button {
  min-width: 70px; /* 设置按钮的最小宽度，确保大小一致 */
  padding: 6px 0; /* 调整内边距 */
  text-align: center; /* 居中对齐 */
}

.sync-button {
  margin-bottom: 20px;
}

.action-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .action-buttons {
    justify-content: space-between;
  }
}
</style>
